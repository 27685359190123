import { Typography } from "@hero/krypton"
import React, { useEffect } from "react"
import { useForm } from "react-hook-form"
import { useDashboardTranslation } from "../../../../../../../01_technical/translations"
import { FieldContainer } from "../../../../00_shared/components/ui/FieldContainer"
import { FieldSelectContainer } from "../../../../00_shared/components/ui/FieldSelectContainer"
import { FormContainer } from "../../../../00_shared/components/ui/FormContainer"
import { ApplyButton } from "./ApplyButton"
import { FilterSection } from "./FilterSection"

interface AmountForm {
  amountOption: "under" | "equal" | "over"
  amount: number
}

interface AmountFilterProps {
  amountUnder?: number
  amountEqual?: number
  amountOver?: number
  setAmountUnder: (amount: number) => void
  setAmountEqual: (amount: number) => void
  setAmountOver: (amount: number) => void
  onApply: (data: AmountForm) => void
  onClose?: () => void
  hideHeader?: boolean
  showApplyButton?: boolean
}

export const AmountFilter: React.FC<AmountFilterProps> = ({
  amountUnder,
  amountEqual,
  amountOver,
  setAmountUnder,
  setAmountEqual,
  setAmountOver,
  onApply,
  onClose,
  hideHeader = false,
  showApplyButton = true,
}) => {
  const { t } = useDashboardTranslation()
  const { handleSubmit, register, watch } = useForm<AmountForm>({
    defaultValues: {
      amountOption: amountUnder ? "under" : amountEqual ? "equal" : amountOver ? "over" : undefined,
      amount: Number(amountUnder ?? amountEqual ?? amountOver ?? 0) / 100,
    },
  })

  const watchedAmountOption = watch("amountOption")
  const watchedAmount = watch("amount")

  useEffect(() => {
    if (watchedAmountOption === "under") {
      setAmountUnder(watchedAmount * 100)
    } else if (watchedAmountOption === "equal") {
      setAmountEqual(watchedAmount * 100)
    } else if (watchedAmountOption === "over") {
      setAmountOver(watchedAmount * 100)
    }
  }, [watchedAmountOption, watchedAmount, setAmountUnder, setAmountEqual, setAmountOver])

  const submitAmount = (data: AmountForm) => {
    onApply(data)
    onClose && onClose()
  }

  return (
    <FilterSection>
      {!hideHeader && (
        <Typography as="h3" $variant="body-4-semibold">
          {t("accounts.transaction.list.filters.amountLabel")}
        </Typography>
      )}
      <FormContainer method="dialog" onSubmit={handleSubmit(submitAmount)}>
        <FieldSelectContainer {...register("amountOption")}>
          <option value="under">{t("accounts.transaction.list.filters.amount.lessThan")}</option>
          <option value="equal">{t("accounts.transaction.list.filters.amount.equal")}</option>
          <option value="over">{t("accounts.transaction.list.filters.amount.greaterThan")}</option>
        </FieldSelectContainer>
        <FieldContainer type="number" placeholder="10" step="0.1" aria-invalid {...register("amount")} />
        {showApplyButton && <ApplyButton type="submit">{t("accounts.transaction.list.filters.apply")}</ApplyButton>}
      </FormContainer>
    </FilterSection>
  )
}
