import { gql } from "@apollo/client"
import { useIsBA2faActive } from "../../../../../../00_shared/hooks/useFeatureFlag.hook"
import { useHeroMutation } from "../../../../../../01_technical/requesting/useHeroMutation/useHeroMutation"
import { use2faChallenge } from "../../../../../../Auth/Challenge2fa/use2faChallenge.hooks"

const SEND_BA_EXTERNAL_WIRE = gql`
  mutation SendBAExternalWire(
    $beneficiaryId: String!
    $businessAccountLedgerId: String!
    $amount: Float!
    $reference: String
  ) {
    sendBAExternalWire(
      beneficiaryId: $beneficiaryId
      businessAccountLedgerId: $businessAccountLedgerId
      amount: $amount
      reference: $reference
    ) {
      ... on SendBAExternalWireOutput {
        beneficiaryName
        amount
        transactionId
      }
      ... on SimpleApiError {
        errorCode
      }
      ... on ValidationErrors {
        errorCode
        validationErrors {
          path
          validationError
        }
      }
      ... on GqlHeroError {
        errorCode
        message
      }
    }
  }
`

type SendBAExternalWireOutput = {
  beneficiaryName: string
  amount: number
  transactionId: string
}

type SendBAExternalWireVariables = {
  beneficiaryId: string
  businessAccountLedgerId: string
  amount: number
  reference?: string
}

export const useSendBAExternalWire = () => {
  const isBA2FAActive = useIsBA2faActive()
  const with2fa = useSendBAExternalWireWith2fa()
  const without2fa = useSendBAExternalWireWithout2fa()

  if (isBA2FAActive) {
    return {
      mutate: with2fa.mutate,
      loading: with2fa.loading,
      error: with2fa.error,
      data: with2fa.data,
      is2faModalOpen: with2fa.is2faModalOpen,
    }
  } else {
    return {
      mutate: without2fa.mutate,
      loading: without2fa.loading,
      error: without2fa.error,
      data: without2fa.data,
      is2faModalOpen: false,
    }
  }
}

const useSendBAExternalWireWith2fa = () => {
  const {
    mutationWith2fa: sendBAExternalWire,
    mutationState: { data, error, loading },
    isModalOpen,
  } = use2faChallenge<{ sendBAExternalWire: SendBAExternalWireOutput }, SendBAExternalWireVariables>(
    SEND_BA_EXTERNAL_WIRE,
  )

  const mutate = async (input: SendBAExternalWireVariables) => {
    const result = await sendBAExternalWire(input)
    return result.data?.sendBAExternalWire
  }

  return { mutate, loading, error, data, is2faModalOpen: isModalOpen }
}

const useSendBAExternalWireWithout2fa = () => {
  const [mutate, { data, loading, error }] = useHeroMutation<SendBAExternalWireOutput, SendBAExternalWireVariables>({
    gqlQuerySchema: SEND_BA_EXTERNAL_WIRE,
  })

  const sendBAExternalWire = async (input: SendBAExternalWireVariables) => {
    const result = await mutate({ variables: input })
    return result
  }

  return { mutate: sendBAExternalWire, loading, error, data }
}
