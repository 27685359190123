import { Header, Tab, Tabs, Typography } from "@hero/krypton"
import { Link, Outlet, useLocation } from "react-router-dom"
import styled from "styled-components"
import { useDashboardTranslation } from "../../01_technical/translations"
import { HEADER_COLORS } from "../../env_variables"
import { useProductContext } from "./ProductScopeContext"
import { NewLinkButton } from "./components/NewLinkButton"

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.white};
  flex: 1;
`

export const CollectionScreen = () => {
  const { t } = useDashboardTranslation()
  const location = useLocation()
  const productContext = useProductContext()

  return (
    <PageWrapper>
      {productContext?.isAtLeastOneProductEnabled && (
        <Header $colors={HEADER_COLORS}>
          <Row>
            <Typography $variant="title-3-inter-bold">{t("collection.list.title")}</Typography>
            <NewLinkButton />
          </Row>
          <Tabs>
            <Tab<typeof Link>
              as={Link}
              to="/collection/list?kind=all"
              data-test-id="merchant-collection"
              $isSelected={location.pathname === "/collection/list" && location.search.includes("kind=all")}
            >
              {t("collection.list.tabs.all")}
            </Tab>
            <Tab<typeof Link>
              as={Link}
              to="/collection/list?kind=link"
              data-test-id="merchant-collection-link"
              $isSelected={location.pathname === "/collection/list" && location.search.includes("kind=link")}
            >
              {t("collection.list.tabs.offline")}
            </Tab>
            <Tab<typeof Link>
              as={Link}
              to="/collection/list?kind=checkout"
              data-test-id="merchant-collection-checkout"
              $isSelected={location.pathname === "/collection/list" && location.search.includes("kind=checkout")}
            >
              {t("collection.list.tabs.online")}
            </Tab>
            <Tab<typeof Link>
              as={Link}
              to="/collection/settings"
              data-test-id="merchant-collection-settings"
              $isSelected={location.pathname.includes("/collection/settings")}
            >
              {t("collection.list.tabs.settings")}
            </Tab>
          </Tabs>
        </Header>
      )}

      <Outlet />
    </PageWrapper>
  )
}
