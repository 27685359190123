import { Separator, Typography } from "@hero/krypton"
import { useCallback, useEffect, useState } from "react"
import { useNavigate, useNavigationType } from "react-router-dom"
import styled from "styled-components"
import { FlexContainer, FlexItem } from "../../../../00_shared/components/Flex"
import { ScrollArea } from "../../../../00_shared/components/ScrollArea"
import { Sheet, SheetContent, SheetHeader, SheetTitle } from "../../../../00_shared/components/Sheet"
import { useCommonTranslation, useDashboardTranslation } from "../../../../01_technical/translations"
import { UserLocale } from "../../../../business/enums/User.enum"
import { TransactionNote } from "../../00_shared/components/TransactionNote"
import { TransactionReceipt } from "../../00_shared/components/TransactionReceipt"
import { isNegative } from "../../00_shared/utils"
import { useOperationDetails } from "../00_shared/hooks/useOperationDetails"
import { TransactionDetails } from "./components/transactions/TransactionInformations/TransactionDetails"
import { TransactionSteps } from "./components/transactions/TransactionInformations/TransactionSteps"
import { formatDetails, FormattedDetails } from "./utils"

const ScrollAreaContainer = styled(ScrollArea)`
  height: calc(100% - 4.5rem);
  padding-right: 0.5rem;
`

const Amount = styled(Typography).attrs(() => ({
  $variant: "title-3-semibold",
}))`
  color: ${({ theme }) => theme.colors.grey.$600};
`

const Date = styled(Typography).attrs(() => ({
  $variant: "caption-2",
}))`
  color: ${({ theme }) => theme.colors.grey.$500};
`

export const AccountTransactionInformations = () => {
  const { t, i18n } = useCommonTranslation()
  const { t: td } = useDashboardTranslation()
  const [isOpen, setIsOpen] = useState(true)
  const [formattedOperationDetails, setFormattedOperationDetails] = useState<FormattedDetails>()
  const navigate = useNavigate()
  const navigationType = useNavigationType()
  const { operationDetails, loading } = useOperationDetails()

  const transactionDetailsNotFound = operationDetails === null && !loading

  const handleAnimationEnd = () => {
    navigateBack()
  }

  const navigateBack = useCallback(() => {
    if (!isOpen || transactionDetailsNotFound) {
      if (navigationType === "PUSH") {
        navigate(-1)
      } else {
        navigate("..")
      }
    }
  }, [isOpen, transactionDetailsNotFound, navigationType, navigate])

  useEffect(() => {
    if (operationDetails !== null) {
      setFormattedOperationDetails(
        formatDetails(operationDetails, t, UserLocale[i18n.language as keyof typeof UserLocale]),
      )
    }
  }, [operationDetails, t, i18n])

  if (!formattedOperationDetails) {
    navigateBack()
    return
  }

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetContent side="right" onAnimationEndCapture={handleAnimationEnd}>
        <SheetHeader>
          <SheetTitle>{formattedOperationDetails.pageTitle}</SheetTitle>
        </SheetHeader>
        <ScrollAreaContainer height="calc(100vh - 5rem)" width="100%">
          <FlexContainer $direction="column" $gap="1.2rem">
            <FlexItem>
              <FlexContainer $justify="space-between" $align="center">
                <Typography $variant="title-3-semibold">{formattedOperationDetails.label}</Typography>
                <Amount>{formattedOperationDetails.amount}</Amount>
              </FlexContainer>
              <Date>{formattedOperationDetails.createdAt}</Date>
            </FlexItem>
            <Separator />
            {formattedOperationDetails.status && isNegative(operationDetails?.amount) && (
              <FlexItem>
                <TransactionSteps statuses={formattedOperationDetails.status} />
              </FlexItem>
            )}
            {formattedOperationDetails.operationItems.length > 0 && (
              <FlexItem>
                <TransactionDetails items={formattedOperationDetails.operationItems} />
              </FlexItem>
            )}
            <Separator />
            <FlexItem>
              <TransactionReceipt
                title={td("accounts.transactionInformations.receipt.title")}
                operationId={operationDetails?.id || ""}
                fileUrl={operationDetails?.justificativePath}
              />
            </FlexItem>
            <Separator />
            <FlexItem>
              <TransactionNote
                title={td("accounts.transactionInformations.note.title")}
                operationId={operationDetails?.id || ""}
                note={operationDetails?.note}
              />
            </FlexItem>
          </FlexContainer>
        </ScrollAreaContainer>
      </SheetContent>
    </Sheet>
  )
}
