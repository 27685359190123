import { gql } from "@apollo/client"
import { useHeroQuery } from "../../../../../01_technical/requesting/useHeroQuery/useHeroQuery"
import { BNPLProductType, PaymentStatus, PaymentType } from "../../enums/PaymentCore.enum"

const GET_PAYMENT_BNPL = gql`
  query getPaymentsBnpl($pagination: PaginationInput!, $filters: GetPaymentsBnplInput!) {
    getPaymentsBnpl(pagination: $pagination, filters: $filters) {
      ... on GetPaymentsBnplOutput {
        payments {
          id
          amount
          createdAt
          paymentStatus
          paymentType
          installmentCount
          daysBeforeDueDate
          customerEmail
          productType
          customerName
          customerSiret
          customerNormalizedContactPhone
          totalFee
          feeFlat
          vat
          providerFees
          reference
          installments {
            amount
            status
            dueDate
          }
        }
      }

      ... on ValidationErrors {
        validationErrors {
          path
          validationError
        }
      }

      ... on GqlHeroError {
        errorCode
      }
    }
  }
`

export type PaymentInstallment = {
  amount: number
  dueDate: string
  paid: boolean
  refunded: boolean
  refundedAt?: string
}

type Payment = {
  id: string
  amount: number
  createdAt: string
  paymentStatus: PaymentStatus
  paymentType: PaymentType
  installmentCount: number
  daysBeforeDueDate: number
  customerEmail: string
  productType: BNPLProductType
  customerName: string
  customerSiret?: string
  customerNormalizedContactPhone: string
  totalFee: number
  feeFlat: number
  vat: number
  providerFees: number
  reference: string
  installments?: PaymentInstallment[]
}

type GetPaymentsBnplResponse = {
  payments: Payment[]
}

type GetPaymentsBnplVariables = {
  id?: string
}

export const useGetPaymentBnpl = (variables: GetPaymentsBnplVariables) => {
  const { data, loading, error, refetch } = useHeroQuery<GetPaymentsBnplResponse>({
    gqlQuerySchema: GET_PAYMENT_BNPL,
    variables: {
      filters: {
        id: variables.id,
      },
      pagination: {
        pageNumber: 1,
        pageSize: 1,
      },
    },
    skip: !variables.id,
  })

  return {
    payment: data?.payments.length ? data.payments[0] : null,
    loading,
    error,
    refetch,
  }
}
