import { gql } from "@apollo/client"
import { useIsBeneficiary2faActive } from "../../../../../00_shared/hooks/useFeatureFlag.hook"
import { useHeroMutation } from "../../../../../01_technical/requesting/useHeroMutation/useHeroMutation"
import { use2faChallenge } from "../../../../../Auth/Challenge2fa/use2faChallenge.hooks"

const CREATE_BENEFICIARY = gql`
  mutation createBeneficiary($input: CreateBeneficiaryInput!) {
    createBeneficiary(input: $input) {
      ... on CreateBeneficiaryOutput {
        success
        beneficiaryId
      }
      ... on GqlHeroError {
        errorCode
        message
      }

      ... on ValidationErrors {
        errorCode
        validationErrors {
          path
          validationError
        }
      }
    }
  }
`

type Beneficiary = {
  fullName: string
  iban: string
  label: string | null
  bankName?: string
}

type CreateBeneficiaryOutput = {
  success: boolean
  beneficiaryId: string
}

type CreateBeneficiaryInput = {
  input: Beneficiary
}

const useAddBeneficiaryWith2fa = () => {
  const {
    mutationWith2fa: addBeneficiary,
    mutationState: { data, error, loading },
    isModalOpen,
  } = use2faChallenge<{ createBeneficiary: CreateBeneficiaryOutput }, CreateBeneficiaryInput>(CREATE_BENEFICIARY)

  const mutate = async (input: Beneficiary) => {
    const result = await addBeneficiary({ input })
    return result.data?.createBeneficiary
  }

  return { mutate, loading, error, data, is2faModalOpen: isModalOpen }
}

const useAddBeneficiaryWithout2fa = () => {
  const [mutate, { data, loading, error }] = useHeroMutation<CreateBeneficiaryOutput, CreateBeneficiaryInput>({
    gqlQuerySchema: CREATE_BENEFICIARY,
  })

  const addBeneficiary = async (input: Beneficiary) => {
    const result = await mutate({ variables: { input } })
    return result
  }

  return { mutate: addBeneficiary, loading, error, data }
}

export const useAddBeneficiary = () => {
  const isBeneficiary2FAActive = useIsBeneficiary2faActive()
  const with2fa = useAddBeneficiaryWith2fa()
  const without2fa = useAddBeneficiaryWithout2fa()

  if (isBeneficiary2FAActive) {
    return {
      mutate: with2fa.mutate,
      loading: with2fa.loading,
      error: with2fa.error,
      data: with2fa.data,
      is2faModalOpen: with2fa.is2faModalOpen,
    }
  } else {
    return {
      mutate: without2fa.mutate,
      loading: without2fa.loading,
      error: without2fa.error,
      data: without2fa.data,
      is2faModalOpen: false,
    }
  }
}
