import { useApolloClient } from "@apollo/client"
import { useEffect, useMemo, useRef } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useMutationWith2fa } from "../Challenge2fa/useMutationWith2fa"
import {
  Autologin2faFunctionalError,
  AUTOLOGIN_DASHBOARD_REQUEST,
  AutologinArgs,
  AutologinResponse,
  AutologinSuccess,
  convertAutologin2faErrorToSignin2faError,
  Setup2faResponse,
} from "../Login/login.requests"
import { use2faSetup } from "../Setup2fa/setup2fa.hooks"

const isAutologinSuccess = (response: AutologinResponse): response is AutologinSuccess => {
  return (response as AutologinSuccess).autologin.token !== undefined
}

const is2faSetupRequired = (response: AutologinResponse): response is Autologin2faFunctionalError => {
  const autologin2faError = response as Autologin2faFunctionalError
  return (
    autologin2faError.autologin.errorCode === "FUNCTIONAL_ERROR" &&
    autologin2faError.autologin.message === "2FA_SETUP_REQUIRED"
  )
}

export const Autologin = () => {
  const navigate = useNavigate()
  const client = useApolloClient()
  const [queryParams] = useSearchParams()
  const path = queryParams.get("path")
  const urlToRedirectTo = useMemo(() => path || "/", [path])
  const autologinToken = queryParams.get("autologinToken")
  const autologinEmail = queryParams.get("autologinEmail")
  const [autologin, { error: autologinError }] = useMutationWith2fa<AutologinResponse, AutologinArgs>(
    AUTOLOGIN_DASHBOARD_REQUEST,
  )
  const hasStarted = useRef(false)
  const [setup2fa, { error: setup2faError }] = use2faSetup<Setup2faResponse>()

  useEffect(() => {
    if (setup2faError && setup2faError?.check2FAEnrollChallenge?.errorCode) {
      const errorCode = setup2faError.check2FAEnrollChallenge.errorCode

      const params = new URLSearchParams({ error: errorCode }).toString()
      window.location.href = `/signin?${params}`
    }
  }, [setup2faError])

  useEffect(() => {
    if (autologinError && autologinError.message) {
      const errorCode = autologinError.message
      const params = new URLSearchParams({ error: errorCode }).toString()
      window.location.href = `/signin?${params}`
    }
  }, [autologinError])

  useEffect(() => {
    if (hasStarted.current || !autologinEmail || !autologinToken) {
      return
    }
    hasStarted.current = true

    const handleAutologin = async (email: string, autologinToken: string) => {
      const result = await autologin({
        variables: {
          email,
          autologinToken,
        },
      })
      if (isAutologinSuccess(result.data)) {
        const token = result.data.autologin.token
        localStorage.setItem("token", token)
        // Clear store cache to avoid graphql having cache from another session (AKA another merchant)
        await client.clearStore()
        console.log(urlToRedirectTo)
        navigate(urlToRedirectTo, { replace: true })
      }
      return result
    }

    const handleSetup2faResponse = async (response: Setup2faResponse, email: string, autologinToken: string) => {
      const challenge = response.check2FAEnrollChallenge

      if ("status" in challenge && challenge.status === "SUCCESS") {
        const resultAfterSetup = await handleAutologin(email, autologinToken)
        if (isAutologinSuccess(resultAfterSetup.data)) {
          return true
        }
      }
      return false
    }

    const handle2faSetup = async (email: string, autologinToken: string, data: Autologin2faFunctionalError) => {
      const signin2faError = convertAutologin2faErrorToSignin2faError(data)
      const setup2faResult = await setup2fa(signin2faError)
      const challenge = setup2faResult as Setup2faResponse
      const setup2faResponse = await handleSetup2faResponse(challenge, email, autologinToken)
      if (setup2faResponse) {
        return
      }
    }

    const auto = async (email: string, autologinToken: string) => {
      try {
        const result = await handleAutologin(email, autologinToken)
        if (isAutologinSuccess(result.data)) return

        if (is2faSetupRequired(result.data)) {
          await handle2faSetup(email, autologinToken, result.data)
        } else {
          console.error("Unexpected autologin response")
          const params = new URLSearchParams({ error: "UNKNOWN_ERROR" }).toString()
          window.location.href = `/signin?${params}`
        }
      } catch (e) {
        console.error("Autologin error:", (e as Error).message)
        const params = new URLSearchParams({ error: (e as Error).message }).toString()
        window.location.href = `/signin?${params}`
      }
    }

    auto(autologinEmail, autologinToken)
  }, [autologin, autologinEmail, autologinToken, client, navigate, urlToRedirectTo, setup2fa])

  return <div></div>
}
