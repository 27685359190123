import { Navigate } from "react-router-dom"
import { PaymentDetailsScreen } from "./00_shared/components/PaymentDetails/PaymentDetails.screen"
import { CollectionScreen } from "./Collection.screen"
import { HOCProtectActivatedPage, NoProductActivated } from "./components/NoProductActivated"
import { CreateLinkScreen } from "./Link/Create/CreateLink.screen"
import { CollectionListScreen } from "./List/List.screen"
import { ProductScope } from "./ProductScopeContext"
import { Accounting } from "./Settings/Accounting"
import { Plugins } from "./Settings/Plugins"
import { Settings } from "./Settings/Settings"
import { SettingsCheckout } from "./Settings/SettingsCheckout"
import { SettingsLink } from "./Settings/SettingsLink"

export const collectionRoute = {
  path: "collection",
  element: <ProductScope />,
  children: [
    {
      element: <CollectionScreen />,
      children: [
        {
          index: true,
          element: <Navigate to="list?kind=all" />,
        },
        {
          path: "list",
          element: (
            <HOCProtectActivatedPage>
              <CollectionListScreen />
            </HOCProtectActivatedPage>
          ),
        },
        {
          path: "settings",
          element: (
            <HOCProtectActivatedPage>
              <Settings />
            </HOCProtectActivatedPage>
          ),
          children: [
            {
              index: true,
              element: <Navigate to="link" />,
            },
            {
              path: "link",
              element: <SettingsLink />,
            },
            {
              path: "checkout",
              element: <SettingsCheckout />,
            },
            {
              path: "plugins",
              element: <Plugins />,
            },
            {
              path: "accounting",
              element: <Accounting />,
            },
          ],
        },
        {
          path: "no-product-activated",
          element: <NoProductActivated />,
        },
      ],
    },
    {
      path: "new-link/*",
      element: (
        <HOCProtectActivatedPage>
          <CreateLinkScreen />
        </HOCProtectActivatedPage>
      ),
    },
    {
      path: "detail/:id/*",
      element: (
        <HOCProtectActivatedPage>
          <PaymentDetailsScreen />
        </HOCProtectActivatedPage>
      ),
    },
  ],
}
