import { Navigate, Outlet, RouteObject, useParams } from "react-router-dom"
import useLocalStorage from "./00_shared/hooks/useLocalStorage.hook"
import { Connect } from "./01_technical/client"
import { RouterConnectedTranslation } from "./01_technical/translations"
import { authRoutes } from "./Auth/auth.routes"
import { acceleratedPayoutsRoute } from "./Merchant/AcceleratedPayout/acceleratedPayouts.route"
import { accountRoutes } from "./Merchant/BusinessAccount/routes/account.routes"
import { transfersRoutes } from "./Merchant/BusinessAccount/routes/transfers.routes"
import { checkout_cashRoute } from "./Merchant/CheckoutAndCash/checkout_cash.route"
import { transactionRoute } from "./Merchant/CheckoutAndCash/Cockpit/Transaction/transaction.route"
import { collectionRoute } from "./Merchant/Collection/Collection.route"
import { creditWireTransfersRoute } from "./Merchant/CreditWireTransfer/CreditWireTransfer.route"
import { issuingCardsRoutes } from "./Merchant/IssuingCards/routes/issuingCards.routes"
import { Merchant } from "./Merchant/merchant"
import { createPaymentRoute } from "./Merchant/paymentLinkCreate/createPayment.route"
import { SetupStandingOrder } from "./Merchant/SetupStandingOrder/SetupStandingOrder.screen"
import { accountingRoute } from "./Merchant/Tools/Accounting/accounting.route"
import { settingsRoute } from "./Merchant/Tools/Settings/Settings.route"

const withConnect = (component: JSX.Element) => <Connect>{component}</Connect>

const NavigateWithLangPrefix = ({ to }: { to: string }) => {
  const { lang } = useParams()
  return <Navigate to={`${lang ? `/${lang}` : ""}${to}`} />
}

const DynamicRootNavigation = () => {
  const [isBOV3] = useLocalStorage("BO_V3", false)
  const defaultPath = !!isBOV3 ? "/accounts/transactions" : "/checkout_cash/cockpit"
  return <NavigateWithLangPrefix to={defaultPath} />
}

export const appRoutes: RouteObject[] = [
  {
    path: "/:lang?/*",
    element: <RouterConnectedTranslation />,
    children: [
      ...authRoutes,
      {
        path: "*",
        element: <Outlet />,
        children: [
          {
            path: "*",
            element: withConnect(<Merchant />),
            children: [
              { index: true, element: <DynamicRootNavigation /> },
              { path: "*", element: <DynamicRootNavigation /> },
              settingsRoute,
              acceleratedPayoutsRoute,
              checkout_cashRoute,
              creditWireTransfersRoute,
              collectionRoute,
              createPaymentRoute,
              accountingRoute,
              transactionRoute,
              accountRoutes,
              transfersRoutes,
              issuingCardsRoutes,
            ],
          },
        ],
      },
      {
        path: "swan/setup-standing-order/*",
        element: withConnect(<Outlet />),
        children: [
          {
            index: true,
            element: <SetupStandingOrder />,
          },
        ],
      },
    ],
  },
]
