import { DateTime } from "luxon"
import { CommonTFunction } from "../../../01_technical/translations"
import { UserLocale } from "../../../business/enums/User.enum"

interface OperationStatus {
  initiateAt?: Date
  processedAt?: Date
  payoutAt?: Date
}

export const formatOperationStatus = (status: OperationStatus, t: CommonTFunction, local: UserLocale) => {
  return [
    {
      label: `${t("operation.details.status.initiate")}`,
      value: status.initiateAt
        ? DateTime.fromJSDate(new Date(status.initiateAt))
            .setLocale(local)
            .toLocaleString(DateTime.TIME_24_WITH_SECONDS)
        : undefined,
    },
    {
      label: `${t("operation.details.status.process")}`,
      value: status.processedAt
        ? DateTime.fromJSDate(new Date(status.processedAt))
            .setLocale(local)
            .toLocaleString(DateTime.TIME_24_WITH_SECONDS)
        : undefined,
    },
    {
      label: `${t("operation.details.status.payout")}`,
      value: status.payoutAt
        ? DateTime.fromJSDate(new Date(status.payoutAt)).setLocale(local).toLocaleString(DateTime.TIME_24_WITH_SECONDS)
        : undefined,
    },
  ]
}

export const isNegative = (amount: unknown) => typeof amount === "number" && amount < 0
