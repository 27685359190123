import { PaymentConfigurationScope } from "../00_shared/enums/PaymentCore.enum"
import { useProductContext } from "../ProductScopeContext"
import { SettingsProductScope } from "./SettingsProductScope"

export const SettingsCheckout = () => {
  const productContext = useProductContext()

  return (
    <SettingsProductScope
      titleKey="collection.settings.checkout"
      enablePluginsNavigation
      scopeBNPLEnabled={productContext?.checkoutScope === PaymentConfigurationScope.BNPL}
      scopePAY1xEnabled={productContext?.checkoutScope === PaymentConfigurationScope.PAY1X}
      productDisabled={!!!productContext?.checkoutEnabled}
    />
  )
}
