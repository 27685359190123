import { Button } from "@hero/krypton"
import { Link, Outlet } from "react-router-dom"
import styled from "styled-components"
import { useCommonTranslation } from "../../../../01_technical/translations"
import { useBusinessAccountContext } from "../../businessAccount.context"

const Container = styled.div`
  display: inline;
`

const ButtonContainer = styled.div`
  position: absolute;
  top: 2.75rem;
  right: 3rem;
  display: flex;
  gap: 0.5rem;
  button {
    min-width: unset;
  }

  @media (max-width: 768px) {
    top: 4rem;
    right: 1rem;
  }
`

export const TransferHistoryRoot = () => {
  const { isBaActive } = useBusinessAccountContext()
  const { t } = useCommonTranslation()
  return (
    <Container>
      <ButtonContainer>
        <Button
          as={Link}
          to="/transfers/create"
          size="small"
          isLoading={false}
          $variant="primary"
          disabled={!isBaActive}
        >
          {t("wire.history.action")}
        </Button>
      </ButtonContainer>
      <Outlet />
    </Container>
  )
}
