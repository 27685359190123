import { gql } from "@apollo/client"
import { useIsBeneficiary2faActive } from "../../../../00_shared/hooks/useFeatureFlag.hook"
import { useHeroMutation } from "../../../../01_technical/requesting/useHeroMutation/useHeroMutation"
import { use2faChallenge } from "../../../../Auth/Challenge2fa/use2faChallenge.hooks"

export function useCreateBeneficiary() {
  const isBeneficiary2FAActive = useIsBeneficiary2faActive()
  const with2fa = useCreateBeneficiaryWith2fa()
  const without2fa = useCreateBeneficiaryWithout2fa()

  if (isBeneficiary2FAActive) {
    return {
      mutate: with2fa.mutate,
      loading: with2fa.loading,
      error: with2fa.error,
      data: with2fa.data,
    }
  } else {
    return {
      mutate: without2fa.mutate,
      loading: without2fa.loading,
      error: without2fa.error,
      data: without2fa.data,
    }
  }
}

function useCreateBeneficiaryWith2fa() {
  const { mutationWith2fa, mutationState } = use2faChallenge<
    { createBeneficiary: CREATE_BENEFICIARY_SUCCESS_RESPONSE },
    CREATE_BENEFICIARY_PARAMS
  >(CREATE_BENEFICIARY_REQ)

  const mutate = async (input: CreateBeneficiaryInput) => {
    const result = await mutationWith2fa({ input })
    return result.data?.createBeneficiary
  }

  return { mutate, ...mutationState }
}

function useCreateBeneficiaryWithout2fa() {
  const [mutate, { data, loading, error }] = useHeroMutation<
    CREATE_BENEFICIARY_SUCCESS_RESPONSE,
    CREATE_BENEFICIARY_PARAMS
  >({
    gqlQuerySchema: CREATE_BENEFICIARY_REQ,
  })

  const createBeneficiary = async (input: CreateBeneficiaryInput) => {
    console.log("gfdsfg")
    const result = await mutate({ variables: { input } })
    console.log(result)
    return result
  }

  return { mutate: createBeneficiary, data, loading, error }
}

type CreateBeneficiaryInput = {
  fullName: string
  iban: string
  label: string | null
  bankName?: string
}

type CREATE_BENEFICIARY_PARAMS = {
  input: CreateBeneficiaryInput
}

type CREATE_BENEFICIARY_SUCCESS_RESPONSE = {
  success: boolean
  beneficiaryId: string
}

const CREATE_BENEFICIARY_REQ = gql`
  mutation createBeneficiary($input: CreateBeneficiaryInput!) {
    createBeneficiary(input: $input) {
      ... on CreateBeneficiaryOutput {
        success
        beneficiaryId
      }

      ... on GqlHeroError {
        errorCode
        message
      }

      ... on ValidationErrors {
        errorCode
        validationErrors {
          path
          validationError
        }
      }
    }
  }
`
