import { Modal, toaster, Typography } from "@hero/krypton"
import { useState } from "react"
import styled from "styled-components"
import { FileUploadPreview } from "../../../../00_shared/components/FileUploadPreview"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { useOperationDetails } from "../../pro-account/00_shared/hooks/useOperationDetails"
import { useOperationJustificativePresignedUrl } from "../../pro-account/00_shared/hooks/useOperationJustificativePresignedUrl"
import { useUpdateOperationMetadata } from "../../pro-account/00_shared/hooks/useUpdateOperationMetadata"
import { useUploadFile } from "../hooks/useUploadFile"

const ReceiptContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

export const TransactionReceipt = ({
  title,
  operationId,
  fileUrl,
}: {
  title: string
  operationId: string
  fileUrl?: string | null
}) => {
  const { t } = useDashboardTranslation()
  const [isModalOpen, setModalOpen] = useState(false)
  const [isRefreshing, setIsRefreshing] = useState(false)
  const { refetch, loading: operationDetailsLoading } = useOperationDetails(operationId)
  const { fetchSignedUrl, loading: signedUrlLoading } = useOperationJustificativePresignedUrl()
  const { uploadFile, isUploading } = useUploadFile({
    onUploadSuccess: (fp: string) => {
      const justificativeId = fp.split("/").pop()
      updateOperationMetadata({ operationId, metadata: { justificativeId } })
    },
  })
  const { updateOperationMetadata, loading: metadataLoading } = useUpdateOperationMetadata()

  const isLoading = operationDetailsLoading || signedUrlLoading || isUploading || metadataLoading || isRefreshing

  const handleFileChange = async (file: File) => {
    setIsRefreshing(true)
    try {
      const { signedUrl, path } = await fetchSignedUrl(operationId)

      if (!signedUrl) {
        toaster.error(t("fileUpload.error.failedUpload"))
        setIsRefreshing(false)
        return
      }

      await uploadFile(signedUrl, file, path)
      await refetch()
    } catch (error) {
      toaster.error(t("fileUpload.error.failedUpload"))
    } finally {
      setIsRefreshing(false)
    }
  }

  const handleFileDelete = async () => {
    try {
      setIsRefreshing(true)
      await updateOperationMetadata({ operationId, metadata: { justificativeId: null } })
      toaster.success(t("fileUpload.success.deleted"))
      await refetch()
    } catch (error) {
      toaster.error(t("fileUpload.error.failedDeletion"))
    } finally {
      setIsRefreshing(false)
    }
  }

  const handlePreviewClick = () => {
    setModalOpen(true)
  }

  return (
    <ReceiptContainer>
      <Typography $variant="body-4-medium">{title}</Typography>

      <FileUploadPreview
        fileUrl={fileUrl}
        isLoading={isLoading}
        onFileChange={handleFileChange}
        onDelete={handleFileDelete}
        onPreview={handlePreviewClick}
        allowedFileTypes={["image/*", "application/pdf"]}
      />

      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
          <img src={fileUrl ?? undefined} alt="Full-size preview" style={{ width: "100%", borderRadius: "0.5rem" }} />
        </Modal>
      )}
    </ReceiptContainer>
  )
}
