import { Wizard } from "@hero/krypton"
import React from "react"
import { Outlet, useNavigate } from "react-router-dom"
import styled from "styled-components"
import { Dialog, DialogContent } from "../../../../00_shared/components/Dialog"
import { ScrollArea } from "../../../../00_shared/components/ScrollArea"
import { CreateTransferProvider, useCreateTransferContext } from "./CreateTransferContext"

const Container = styled.div`
  height: 100%;
  width: 40rem;
  margin: auto;
  padding: 2rem 0;
`

const WizardWrapper: React.FC = () => {
  const navigate = useNavigate()
  const { steps, clearState } = useCreateTransferContext()

  const handleQuit = () => {
    clearState()
    navigate("/transfers")
  }

  return (
    <Wizard onQuit={handleQuit} steps={steps}>
      <ScrollArea height="calc(100% - 6rem)">
        <Container>
          <Outlet />
        </Container>
      </ScrollArea>
    </Wizard>
  )
}

export const CreateTransfer: React.FC = () => {
  return (
    <Dialog open={true}>
      <DialogContent disableCloseButton={true} $disableAnimations={true} width="100%" height="100%">
        <CreateTransferProvider>
          <WizardWrapper />
        </CreateTransferProvider>
      </DialogContent>
    </Dialog>
  )
}
