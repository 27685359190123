import { CwtItem, CwtItemStatus } from "./useGetCwtList"

export const getDemoData = (filters: { merchantId: string; status: string | null }): CwtItem[] => {
  const cwtList: CwtItem[] = [
    {
      id: "1",
      amount: 1000000,
      feeRate: 3000,
      feeAmount: {
        totalAmount: 3000,
        vatAmount: 0,
        amountWithoutVAT: 3000,
      },
      appliedAt: new Date(),
      document: new URL("https://www.google.com"),
      installmentCount: 3,
      installments: [
        {
          amount: {
            totalAmount: 1000000,
            feeAmount: {
              totalAmount: 3000,
              vatAmount: 0,
              amountWithoutVAT: 3000,
            },
          },
          dueDate: new Date(),
          position: 1,
          status: "PAID",
        },
        {
          amount: {
            totalAmount: 1000000,
            feeAmount: {
              totalAmount: 3000,
              vatAmount: 0,
              amountWithoutVAT: 3000,
            },
          },
          dueDate: new Date(),
          position: 2,
          status: "PENDING",
        },
        {
          amount: {
            totalAmount: 1000000,
            feeAmount: {
              totalAmount: 3000,
              vatAmount: 0,
              amountWithoutVAT: 3000,
            },
          },
          dueDate: new Date(),
          position: 3,
          status: "PENDING",
        },
      ],
      label: "CWT 1",
      merchantId: "1",
      recipient: {
        beneficiaryId: "1",
        name: "Amo",
        email: "",
        siret: "123456789",
        status: "verified",
      },
      status: CwtItemStatus.APPROVED,
    },
    {
      id: "2",
      amount: 2000000,
      feeRate: 6000,
      feeAmount: {
        totalAmount: 6000,
        vatAmount: 0,
        amountWithoutVAT: 6000,
      },
      appliedAt: new Date(),
      document: new URL("https://www.google.com"),
      installmentCount: 3,
      installments: [
        {
          amount: {
            totalAmount: 2000000,
            feeAmount: {
              totalAmount: 6000,
              vatAmount: 0,
              amountWithoutVAT: 6000,
            },
          },
          dueDate: new Date(),
          position: 1,
          status: "PAID",
        },
        {
          amount: {
            totalAmount: 2000000,
            feeAmount: {
              totalAmount: 6000,
              vatAmount: 0,
              amountWithoutVAT: 6000,
            },
          },
          dueDate: new Date(),
          position: 2,
          status: "PAID",
        },
        {
          amount: {
            totalAmount: 2000000,
            feeAmount: {
              totalAmount: 6000,
              vatAmount: 0,
              amountWithoutVAT: 6000,
            },
          },
          dueDate: new Date(),
          position: 3,
          status: "PAID",
        },
      ],
      label: "CWT 2",
      merchantId: "1",
      recipient: {
        beneficiaryId: "1",
        name: "Amo",
        email: "",
        siret: "123456789",
        status: "verified",
      },
      status: CwtItemStatus.COMPLETED,
    },
    {
      id: "3",
      amount: 3000000,
      feeRate: 9000,
      feeAmount: {
        totalAmount: 9000,
        vatAmount: 0,
        amountWithoutVAT: 9000,
      },
      appliedAt: new Date(),
      document: new URL("https://www.google.com"),
      installmentCount: 3,
      installments: [
        {
          amount: {
            totalAmount: 3000000,
            feeAmount: {
              totalAmount: 9000,
              vatAmount: 0,
              amountWithoutVAT: 9000,
            },
          },
          dueDate: new Date(),
          position: 1,
          status: "PAID",
        },
        {
          amount: {
            totalAmount: 3000000,
            feeAmount: {
              totalAmount: 9000,
              vatAmount: 0,
              amountWithoutVAT: 9000,
            },
          },
          dueDate: new Date(),
          position: 2,
          status: "PAID",
        },
        {
          amount: {
            totalAmount: 3000000,
            feeAmount: {
              totalAmount: 9000,
              vatAmount: 0,
              amountWithoutVAT: 9000,
            },
          },
          dueDate: new Date(),
          position: 3,
          status: "PENDING",
        },
      ],
      label: "CWT 3",
      merchantId: "1",
      recipient: {
        beneficiaryId: "1",
        name: "Amo",
        email: "",
        siret: "123456789",
        status: "verified",
      },
      status: CwtItemStatus.APPROVED,
    },
    {
      id: "4",
      amount: 4000000,
      feeRate: 12000,
      feeAmount: {
        totalAmount: 12000,
        vatAmount: 0,
        amountWithoutVAT: 12000,
      },
      appliedAt: new Date(),
      document: new URL("https://www.google.com"),
      installmentCount: 3,
      installments: [
        {
          amount: {
            totalAmount: 4000000,
            feeAmount: {
              totalAmount: 12000,
              vatAmount: 0,
              amountWithoutVAT: 12000,
            },
          },
          dueDate: new Date(),
          position: 1,
          status: "PAID",
        },
        {
          amount: {
            totalAmount: 4000000,
            feeAmount: {
              totalAmount: 12000,
              vatAmount: 0,
              amountWithoutVAT: 12000,
            },
          },
          dueDate: new Date(),
          position: 2,
          status: "PAID",
        },
        {
          amount: {
            totalAmount: 4000000,
            feeAmount: {
              totalAmount: 12000,
              vatAmount: 0,
              amountWithoutVAT: 12000,
            },
          },
          dueDate: new Date(),
          position: 3,
          status: "PAID",
        },
      ],
      label: "CWT 4",
      merchantId: "1",
      recipient: {
        beneficiaryId: "1",
        name: "Amo",
        email: "",
        siret: "123456789",
        status: "verified",
      },
      status: CwtItemStatus.REJECTED,
    },
    {
      id: "5",
      amount: 5000000,
      feeRate: 15000,
      feeAmount: {
        totalAmount: 15000,
        vatAmount: 0,
        amountWithoutVAT: 15000,
      },
      appliedAt: new Date(),
      document: new URL("https://www.google.com"),
      installmentCount: 3,
      installments: [
        {
          amount: {
            totalAmount: 5000000,
            feeAmount: {
              totalAmount: 15000,
              vatAmount: 0,
              amountWithoutVAT: 15000,
            },
          },
          dueDate: new Date(),
          position: 1,
          status: "PAID",
        },
        {
          amount: {
            totalAmount: 5000000,
            feeAmount: {
              totalAmount: 15000,
              vatAmount: 0,
              amountWithoutVAT: 15000,
            },
          },
          dueDate: new Date(),
          position: 2,
          status: "PAID",
        },
        {
          amount: {
            totalAmount: 5000000,
            feeAmount: {
              totalAmount: 15000,
              vatAmount: 0,
              amountWithoutVAT: 15000,
            },
          },
          dueDate: new Date(),
          position: 3,
          status: "PENDING",
        },
      ],
      label: "CWT 5",
      merchantId: "1",
      recipient: {
        beneficiaryId: "1",
        name: "Amo",
        email: "",
        siret: "123456789",
        status: "verified",
      },
      status: CwtItemStatus.APPROVED,
    },
  ]

  return cwtList.filter((cwt) => cwt.status.toLowerCase() === filters.status?.toLowerCase() || !filters.status)
}
