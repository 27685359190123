import { useApolloClient } from "@apollo/client"
import { ArrowRightIcon, Button, ErrorBlock, Field, Typography } from "@hero/krypton"
import { useEffect, useMemo } from "react"
import { useForm } from "react-hook-form"
import { Trans } from "react-i18next"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import styled from "styled-components"
import { useCommonTranslation } from "../../01_technical/translations"
import { ApiErrors } from "../../Legacy/components/ApiErrors"
import { LEGACY_SIGNIN, ONBOARDING_SIGNIN_URL } from "../../env_variables"
import { useMutationWith2fa } from "../Challenge2fa/useMutationWith2fa"
import { use2faSetup } from "../Setup2fa/setup2fa.hooks"
import { useAuthLayoutContext } from "../auth-layout.context"
import { SIGNIN_DASHBOARD_REQUEST, Setup2faResponse, SigninArgs, SigninResponse, SigninSuccess } from "./login.requests"

const Container = styled.div<{ $isLoggedIn: boolean }>`
  display: ${({ $isLoggedIn }) => ($isLoggedIn ? "none" : "flex")};
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const LoginBox = styled.form`
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 26rem;
  margin-top: auto;
  display: flex;
`

const NoAccount = styled.div`
  margin-top: auto;
  display: flex;
`

const ActionButton = styled.div`
  width: 21.875rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

type SignupForm = {
  email: string
  password: string
}

function isSigninSuccess(response: SigninResponse): response is SigninSuccess {
  return (response as SigninSuccess).signinDashboard.token !== undefined
}

export const Login = () => {
  const navigate = useNavigate()
  const client = useApolloClient()
  const [queryParams] = useSearchParams()
  const path = queryParams.get("path")
  const { animationStatus } = useAuthLayoutContext()
  const { t, unsafeT } = useCommonTranslation()
  const { triggerAnimation } = useAuthLayoutContext()
  const { register, handleSubmit, formState } = useForm<SignupForm>()
  const urlToRedirectTo = useMemo(() => path || "/", [path])
  const [setup2fa, { error: setup2faError }] = use2faSetup<Setup2faResponse>()

  const [signin, { error, loading }] = useMutationWith2fa<SigninResponse, SigninArgs>(SIGNIN_DASHBOARD_REQUEST)

  const getTranslations = (error: string) => {
    const errorTranslations: { [key: string]: string } = {
      "2FA_SETUP_IN_PROGRESS": t("auth.login.error.2faSetupInProgress"),
      "2FA_SETUP_NOT_REQUIRED": t("auth.login.error.2faSetupNotRequired"),
      "2FA_SETUP_ABORTED": t("auth.login.error.2faSetupAborted"),
      "2FA_SETUP_FAILED": t("auth.login.error.2faSetupFailed"),
      UNKNOWN_ERROR: t("auth.login.error.2faSetupUnknownError"),
    }
    return errorTranslations[error] || t("auth.login.error.2faSetupUnknownError")
  }

  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate(urlToRedirectTo, { replace: true })
    }
  }, [urlToRedirectTo, navigate])

  const submit = handleSubmit(async (formData) => {
    try {
      const handleSignin = async (formData: SignupForm): Promise<SigninResponse> => {
        const result = await signin({
          variables: {
            email: formData.email,
            password: formData.password,
          },
        })
        if (isSigninSuccess(result.data)) {
          const token = result.data.signinDashboard.token
          localStorage.setItem("token", token)
          // Clear store cache to avoid graphql having cache from another session (AKA another merchant)
          await client.clearStore()
          await triggerAnimation()
          navigate(urlToRedirectTo, { replace: true })
        }
        return result.data
      }

      const handle2faSetup = async (formData: SignupForm, signinResult: SigninResponse) => {
        const setup2faResult = await setup2fa(signinResult)
        const challenge = (setup2faResult as Setup2faResponse).check2FAEnrollChallenge
        if ("status" in challenge && challenge.status === "SUCCESS") {
          const signinAfter2faSetup = await handleSignin(formData)
          if (isSigninSuccess(signinAfter2faSetup)) {
            return
          }
        }
      }

      const signinResult = await handleSignin(formData)
      if (isSigninSuccess(signinResult)) return

      await handle2faSetup(formData, signinResult)
    } catch (e) {}
  })

  if (!LEGACY_SIGNIN) {
    window.location.href = ONBOARDING_SIGNIN_URL
    return
  }

  return (
    <Container $isLoggedIn={animationStatus !== "not_started"}>
      <LoginBox onSubmit={submit} noValidate>
        <Typography $variant="title-1-bold">{t("auth.login.title")}</Typography>
        <div
          style={{
            height: "1.5rem",
          }}
        />
        <Field
          data-test-id="login-email-input"
          fieldLabel={t("auth.login.emailLabel")}
          placeholder={t("auth.login.emailPlaceholder")}
          type="email"
          {...register("email", { required: t("requiredField") })}
          errorMessage={formState.errors.email?.message}
        />
        <Field
          data-test-id="login-password-input"
          fieldLabel={t("auth.login.passwordLabel")}
          placeholder={t("auth.login.passwordPlaceholder")}
          type="password"
          {...register("password", { required: t("requiredField") })}
          errorMessage={formState.errors.password?.message}
        />
        <ActionButton>
          <Button
            data-test-id="login-connexion-button"
            type="submit"
            rightIcon={ArrowRightIcon}
            disabled={!formState.isDirty}
            isLoading={loading}
            size="medium"
          >
            {t("auth.login.connectButton")}
          </Button>
          <Typography $variant="body-4-link" style={{ textAlign: "right", width: "100%" }}>
            <Link to="/forgot_password">{t("auth.login.forgetButton")}</Link>
          </Typography>
        </ActionButton>
        <ApiErrors
          err={error}
          strategies={{
            INVALID_LOGIN_DATA: () => <>{t("auth.login.error.invalidLoginData")}</>,
            ACCOUNT_NOT_ACTIVE: () => <>{t("auth.login.error.accountNotActive")}</>,
            "2FA_ABORTED": () => <>{t("auth.login.error.2faFailed")}</>,
            "2FA_FAILED": () => <>{t("auth.login.error.2faFailed")}</>,
            "2FA_SETUP_FAILED": () => <>{t("auth.login.error.2faSetupFailed")}</>,
          }}
        />
      </LoginBox>
      {setup2faError && <ErrorBlock>{getTranslations(setup2faError?.check2FAEnrollChallenge?.errorCode)}</ErrorBlock>}
      <NoAccount>
        <Typography $variant="body-4-regular">
          <Trans i18nKey="auth.login.link.tryForFree" t={unsafeT}>
            Vous n'avez pas de compte ?
            <Typography as="a" $variant="body-4-link" href="https://www.heropay.eu/essayez-gratuitement/">
              Créez un compte&nbsp;!
            </Typography>
          </Trans>
        </Typography>
      </NoAccount>
    </Container>
  )
}
