import { gql } from "@apollo/client"
import { useHeroMutation } from "../../../01_technical/requesting/useHeroMutation/useHeroMutation"
import { useHeroQuery } from "../../../01_technical/requesting/useHeroQuery/useHeroQuery"
import { BNPLProductType, PaymentStatus, PaymentType } from "../00_shared/enums/PaymentCore.enum"

const GET_PAYMENT_BNPL = gql`
  query getPaymentsBnpl($pagination: PaginationInput!, $filters: GetPaymentsBnplInput!) {
    getPaymentsBnpl(pagination: $pagination, filters: $filters) {
      ... on GetPaymentsBnplOutput {
        payments {
          id
          amount
          createdAt
          paymentStatus
          paymentType
          installmentCount
          daysBeforeDueDate
          customerEmail
          productType
        }
      }

      ... on ValidationErrors {
        validationErrors {
          path
          validationError
        }
      }

      ... on GqlHeroError {
        errorCode
      }
    }
  }
`

export type Payment = {
  id: string
  amount: number
  createdAt: string
  paymentStatus: PaymentStatus
  paymentType: PaymentType
  installmentCount: number
  daysBeforeDueDate: number
  customerEmail: string
  productType: BNPLProductType
}

type GetPaymentsBnplResponse = {
  payments: Payment[]
}

type GetPaymentsBnplPagination = {
  pageNumber: number
  pageSize: number
}

type GetPaymentsBnplFilters = {
  amountUnder?: number
  amountOver?: number
  amountEqual?: number
  dateFrom?: number
  dateTo?: number
  customerEmail?: string
  paymentStatuses: string[]
  paymentTypes: string[]
  productType?: BNPLProductType
}

type GetPaymentsBnplVariables = {
  filters: GetPaymentsBnplFilters
  pagination: GetPaymentsBnplPagination
}

export const useGetPaymentsBnpl = (variables: GetPaymentsBnplVariables) => {
  const { data, loading, error, refetch } = useHeroQuery<GetPaymentsBnplResponse>({
    gqlQuerySchema: GET_PAYMENT_BNPL,
    variables,
  })

  return {
    payments: data?.payments ?? [],
    loading,
    error,
    refetch,
  }
}

const GET_BNPL_KPIS = gql`
  query getBnplKpis {
    getBnplKpis {
      ... on GetBnplKpisOutput {
        available
        total
      }

      ... on GqlHeroError {
        errorCode
      }
    }
  }
`

type GetBnplKpisResponse = {
  available: number
  total: number
}

export const useGetBnplKpis = () => {
  return useHeroQuery<GetBnplKpisResponse>({
    gqlQuerySchema: GET_BNPL_KPIS,
  })
}
