import { Button, FieldError, FieldInput, Label, toaster, Typography } from "@hero/krypton"
import { zodResolver } from "@hookform/resolvers/zod"
import { isValidIBAN } from "ibantools"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { z } from "zod"
import { DialogContent, Dialog as DialogDefault } from "../../../../00_shared/components/Dialog"
import { FlexContainer, FlexItem } from "../../../../00_shared/components/Flex"
import { ScrollArea } from "../../../../00_shared/components/ScrollArea"
import useMediaQuery from "../../../../00_shared/hooks/useMediaQuery.hook"
import { useDashboardTranslation } from "../../../../01_technical/translations"
import { useBusinessAccountContext } from "../../businessAccount.context"
import BeneficiariesIcon from "../00_shared/icons/beneficiaries-icon.png"
import { useAddBeneficiary } from "./hooks/useAddBeneficiary"

const Dialog = styled(DialogDefault)`
  z-index: -1;
`

const ContentContainer = styled.div`
  width: 30rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 10rem;

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
    padding: 1rem;
    word-break: break-word;
  }
`

const FormContainer = styled.form`
  gap: 1rem;
  display: flex;
  flex-direction: column;
`

const FieldInputContainer = styled(FieldInput)`
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: end;
  @media (min-width: 768px) {
    justify-content: start;
  }
`

type BeneficiaryFormValues = z.infer<typeof beneficiarySchema>

const beneficiarySchema = z.object({
  fullName: z.string().min(1, { message: "Name is required" }),
  iban: z
    .string()
    .min(1, { message: "IBAN is required" })
    .superRefine((iban, ctx) => {
      if (!isValidIBAN(iban)) {
        ctx.addIssue({ code: z.ZodIssueCode.custom, message: "IBAN is invalid", fatal: true })
      }
    }),
  email: z.string().optional(),
  label: z
    .string()
    .trim()
    .min(1, { message: "Label must have at least 1 character" })
    .optional()
    .or(z.literal(""))
    .transform((value) => (value === "" ? null : value))
    .nullable()
    .default(null),
  category: z.string().optional(),
})

export const AddBeneficiary = () => {
  const { isBaActive } = useBusinessAccountContext()
  const { t } = useDashboardTranslation()
  const navigate = useNavigate()
  const { mutate, loading, is2faModalOpen } = useAddBeneficiary()
  const isMobile = useMediaQuery("(max-width: 768px)")

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<BeneficiaryFormValues>({
    resolver: zodResolver(beneficiarySchema),
  })

  const handleClickClose = () => {
    navigate(-1)
  }

  const onSubmit = async (data: BeneficiaryFormValues) => {
    try {
      const result = await mutate(data)
      if (result?.success) {
        toaster.success(t("transfers.addBeneficiary.success"))
        navigate(-1)
      } else {
        throw new Error()
      }
    } catch (error) {
      toaster.error(t("transfers.addBeneficiary.error"))
    }
  }

  return (
    <Dialog open={!is2faModalOpen} onOpenChange={handleClickClose}>
      <DialogContent $disableAnimations={true} width="100%" height="100%">
        <ScrollArea width="100%">
          <ContentContainer>
            <img src={BeneficiariesIcon} alt={"beneficiaries"} width={100} />
            <Typography $variant="title-2-bold">{t("transfers.addBeneficiary.title")}</Typography>
            <FormContainer onSubmit={handleSubmit(onSubmit)}>
              <div>
                <Label>{t("transfers.addBeneficiary.fullNameLabel")}</Label>
                <FieldInputContainer
                  placeholder={t("transfers.addBeneficiary.fullNamePlaceholder")}
                  {...register("fullName")}
                  disabled={!isBaActive}
                />
                <FieldError>{errors.fullName?.message || <>&nbsp;</>}</FieldError>
              </div>
              <FlexContainer $direction={isMobile ? "column" : "row"} $justify="space-between" $gap=".5rem">
                <FlexItem $grow={1}>
                  <Label>{t("transfers.addBeneficiary.ibanLabel")}</Label>
                  <FieldInputContainer
                    placeholder={t("transfers.addBeneficiary.ibanPlaceholder")}
                    {...register("iban")}
                    disabled={!isBaActive}
                  />
                  <FieldError>{errors.iban?.message || <>&nbsp;</>}</FieldError>
                </FlexItem>
              </FlexContainer>
              {/* <div>
                <Label>{t("transfers.addBeneficiary.emailLabel")}</Label>
                <FieldInputContainer
                  placeholder={t("transfers.addBeneficiary.emailPlaceholder")}
                  {...register("email")}
                />
                {errors.email && <ErrorBlock>{errors.email.message}</ErrorBlock>}
              </div> */}
              <FlexContainer $justify="space-between">
                <FlexItem $grow={1}>
                  <Label>{t("transfers.addBeneficiary.labelLabel")}</Label>
                  <FieldInputContainer
                    placeholder={t("transfers.addBeneficiary.labelPlaceholder")}
                    {...register("label")}
                    disabled={!isBaActive}
                  />
                  <FieldError>{errors.label?.message || <>&nbsp;</>}</FieldError>
                </FlexItem>
                {/* <FlexItem>
                  <Label>{t("transfers.addBeneficiary.categoryLabel")}</Label>
                  <FieldInputContainer
                    placeholder={t("transfers.addBeneficiary.categoryPlaceholder")}
                    {...register("category")}
                  />
                  {errors.category && <ErrorBlock>{errors.category.message}</ErrorBlock>}
                </FlexItem> */}
              </FlexContainer>
              <ButtonContainer>
                <Button isLoading={loading} type="submit" $variant="primary" size="medium" disabled={!isBaActive}>
                  {t("transfers.addBeneficiary.saveButton")}
                </Button>
              </ButtonContainer>
            </FormContainer>
          </ContentContainer>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  )
}
